import { type Dispatch, type SetStateAction, useState, useCallback } from 'react';
import useAmpComponent from '@/hooks/useAmpComponent';
import HTMLReactParser from 'html-react-parser';
import classNames from '@/utils/className';
import { DivContentNews } from '@/components/Internals/NewsContent/styles';
import { mglGray } from '@/styles/designSystemColors';

type AccordionItem = {
  title: string;
  content: string;
};

interface AccordionProps {
  items: AccordionItem[];
  openItem?: string;
}

export default function Accordion({ items, openItem }: AccordionProps) {
  const [selected, setSelected] = useState(openItem || '');
  return (
    <ul className="mx-4 my-0 list-none rounded sm:mx-auto sm:my-auto [&_li]:mx-0 [&_li]:px-0">
      {items.map(item => (
        <AccordionItem content={item.content} isOpen={item.title === selected} key={item.title} title={item.title} setSelected={setSelected} />
      ))}
    </ul>
  );
}

interface AccordionItemProps extends AccordionItem {
  isOpen: boolean;
  setSelected: Dispatch<SetStateAction<string>>;
}

function AccordionItem({ isOpen, title, content, setSelected }: AccordionItemProps) {
  const { Icon } = useAmpComponent();
  const [height, setHeight] = useState(0);

  const isOpenProps = {
    Container: 'pb-6',
    Button: 'mb-4 [&>svg]:rotate-180',
  } as const;

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setHeight(node.scrollHeight);
    }
  }, []);

  return (
    <li className={classNames('border-b border-b-mglGray200 bg-mglGray50 px-5 py-4', isOpen && isOpenProps.Container)}>
      <button
        className={classNames(
          'flex w-full appearance-none items-center justify-between gap-8 border-none bg-mglGray50 [&>h2]:w-full [&>h2]:text-left [&>h2]:text-lg [&>svg]:transition-all [&>svg]:duration-300 [&>svg]:ease-ease',
          isOpen && isOpenProps.Button
        )}
        onClick={() => setSelected(isOpen ? '' : title)}>
        <h2>{title}</h2> <Icon icon="mdi:chevron-down" width={18} height={18} color={mglGray.mglGray900} />
      </button>
      <DivContentNews
        className="m-0 max-h-0 w-full overflow-hidden text-mglGray900 transition-all duration-[600ms] first:mt-2"
        ref={measuredRef}
        style={{ maxHeight: isOpen && height }}>
        {HTMLReactParser(content)}
      </DivContentNews>
    </li>
  );
}
